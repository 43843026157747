import $ from "jquery";

(function() {

    let admin = {

        bootstrap: () => {
            admin.initDataToggle();
        },

        initDataToggle: () => {
            $('[data-toggle]').on('mouseover mouseout', function(e) {
                $( $(this).attr("data-toggle") ).toggleClass('hidden', e.type !== 'mouseover');
            });
        }

    };

    $(document).ready( admin.bootstrap )

})();